import React, {useEffect} from 'react'
import fourOfour from '../../public/fourOfour.png'

export default function About() {

  return (
    <div id="pageNotFoundContainer">
        <img id="pageNotFound" src={fourOfour} />
    </div>
  )
}
